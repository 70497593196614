












































































































































import Vue from "vue";
import * as _ from "lodash";
import db from "@/firebase/db";
import fb from "firebase/app";
import DBHelper from "@/tscript/dbHelper";
import Header from "@/components/global/Header.vue";
import { mapState, mapActions } from "vuex";
import MycDialog from "@/components/global/dialog/MycDialog.vue";
import {
  getSpecialityList,
  getGroupTypeList,
} from "@/components/helper/general";
import MycButton from "@/components/global/buttons/MycButton.vue";
import MycCard from "../global/cards/MycCard.vue";

export default Vue.extend({
  name: "HomeGroup",
  components: {
    Header,
    MycDialog,
    MycButton,
    MycCard,
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
      openNewGroupDialog: false,
      groupsList: new Array(),
      loading: false,
      group: {} as any,
      valid: true,
    };
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    getSpecialityList,
    getGroupTypeList,
    async loadData() {
      this.loading = true;
      this.groupsList = await this.dbHelper.getAllDataFromCollection("groups");
      if (this.groupsList.length) {
        this.groupsList = _.sortBy(this.groupsList, (group) =>
          group.participants.includes(this.userData.id)
        );
      }
      this.loading = false;
    },
    async saveGroup() {
      this.group.created_by = this.userData.id;
      this.group.created_by_name =
        this.userData.first_name + " " + this.userData.last_name;
      this.group.created_at = new Date();
      this.group.participants = [this.userData.id];
      const group = await this.dbHelper.addDataToCollection(
        "groups",
        this.group
      );
      const discussion = { group_id: group.id };
      await this.dbHelper.addDataToCollection("discussions", discussion);
      this.loadData();
      this.openNewGroupDialog = false;
    },
    joinGroup(groupId: any) {
      const FieldValue = fb.firestore.FieldValue;
      this.dbHelper
        .updateDataToCollection("groups", groupId, {
          participants: FieldValue.arrayUnion(this.userData.id),
        })
        .then(() => {
          this.loadData();
        });
    },
    goGroup(groupId: any) {
      this.$router
        .push({ name: "group", params: { id: groupId } })
        .catch(() => {});
    },
  },
  created() {
    this.logAction({ event_name: "home_group" });
    this.loadData();
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
});
